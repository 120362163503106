/*
    General
*/
#standard_line{
    border-top: 2px solid #444649;
    width: 50px;
}

.justify_text{
    text-align: justify;
}

.padding_line{
    padding: 20px;
}

.default_text_color{
    color:#444649;
}

/*
    Landing Page
*/

html,#landingPage{
    width: 100;
    height: 100vh;
}

#landingPage{
    position: relative;
    margin-bottom: 0;
    text-align: center;
    /*background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("../components/images/semi_truck.jpg");*/
    /*background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("../components/images/semi_truck.jpg");*/
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("../components/images/akaal_semi_truck.jpg");
    background-size: cover; /* or contain depending on what you want */
    background-position: center center;
    background-repeat: no-repeat;
}

#landingPage_text{
    color: white;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#landingPage_header{
    font-size: 90px;
    color:white;
    font-family: 'Lexend Peta', sans-serif;
}

#landingPage_subheader{
    font-size: 40px;
    color:#FF8000;
    font-family: 'Alata', sans-serif;
}

#landingPage_childheader{
    font-size: 30px;
    color:white;
    font-family: 'Dancing Script', cursive;
}


#landingPage_icons{
    font-size: 75px;
    color: white;
}

#landingPage_icons:hover{
    color:#FF8000;
}

#landingPage_button{
    margin:5px;
}

#landingPage_button:hover{
    color:white;
}


/*
    Nav Bar
*/
.navbar {
    opacity: 0.99;
    margin-bottom: 0;
    z-index: 99 !important;
}

#navbar_text:hover{
    color: #FF8000;
}

#navbardrop:hover{
    color:#FF8000;
}

#navbar_dropdown{
    background-color: #212529;
}

#navbar_dropdown_item{
    background-color: #212529;
    color: #c7c7c7;
}
#navbar_dropdown_item:hover{
    color: #FF8000;
}

/*
    Homepage
*/
#quote_icons{
    color:#FF8000;
    font-size: 100px;
}

/*
    FEATURES
*/
#features_section{
    background-color: white;
}

#features_statement{
    text-align: justify;
}

.features_row{
    padding-bottom: 10px;
}

.features_card{
    background-color: #faf3ec;
    border: none;
    border-radius: 15px;
}

.features_block{
    padding: 30px;
}

#features_icons{
    color:#FF8000;
    font-size: 70px;
}
#services_list{
    display: inline-block;
    text-align: left;
}
#services_icons{
    padding-right: 10px;
    
}

#white_banner{
    background-color: white;
}
#about_section{
    background-color: #d4d8d8;
    
}
#white_background_text{
    color:#444649;
}
#homepage_icons{
    color:gold;
    /*font-size: 120px;*/
    font-size: 50px;
    background-color: #fcf9e9;
    padding: 15px;
    border-radius: 30%;
}
#insurance_types{
    padding: 50px;
}

#homepage_icon_text{
    color: #616161;
    /*line-height: 75%;*/
}
#homepage_title_text{
    color: #616161;
    line-height: 85%;
}
#homepage_info_icon{
    color:#c2c6c6;
    font-size: 240px;
}
#homepage_skills{
    background-color: white;
}
#testimonial_quote{
    color: blue;
}
.checked{
    color:orange;
}

.zoom{
    padding: 20px;
    transition: transform .2s; /* Animation */
}

.zoom:hover {
    -ms-transform: scale(1.1); /* IE 9 */
    -webkit-transform: scale(1.1); /* Safari 3-8 */
    transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

#homepage_links:link{
    text-decoration: none;
}
#homepage_links:visited{
    text-decoration: none;
}

.homepage_image{
    padding: 10px;
}

/*About Us*/
.about_sections{
    padding-bottom: 20px;
    padding-top: 20px;
    
}
.about_text{
    padding-left: 30px;
    padding-right: 30px;
    text-align: justify;
}
#about_picture{
    padding-bottom: 30px;
}


/*Careers Page*/
#careers_section{
    background-image: url('../components/images/highway.jpg');
    background-size: cover; /* or contain depending on what you want */
    background-position: center center;
    background-repeat: no-repeat;
}

#careers_box{
    opacity: 0.95;
}

#careers_description{
    text-align: left;
}

/*Contact Page*/
#contact_section{
    background-color: white;
}
.contact_label{
    float: left;
}
.contact_button{
    float: left;
}


#contact_icons{
    color:#FF8000;
    /*font-size: 120px;*/
    font-size: 70px;
    background-color: #faf3ec;
    padding: 20px;
    border-radius: 30%;
}

/*
    Thanks Page
*/
#thankspage_icons{
    color:gold;
    font-size: 120px;
}

/*
    Error Page
*/
#errorpage_icons{
    color: #ff0000;
    font-size: 120px;
}




/*
    Footer
*/
#footer_icon{
    color:#999c9f;
}

footer{
    background-color: #343a40;
}

.footer_name{
    color:white;
}
